<template>
  <div class="container">
    <div class="borrow-header">
      <img
        alt="logo"
        class="borrow_logo"
        src="../../assets/battandgo/img_logo.png"
      />
      <img
        alt="scan"
        class="borrow_scan_guid"
        src="../../assets/battandgo/img_borrow_error.png"
      />
      <div class="borrow_desc">{{ i18n.borrow_error }}</div>
    </div>
    <div class="borrow-main">
      <RecommendedStations />
      <div
        class="borrow_btn btn"
        @click="goRouter(`/homeMap?qrcode=${qrcode}`)"
      >
        <span class="text">{{ i18n.borrow_text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

import RecommendedStations from "./recommendedStations"
export default {
  components: { RecommendedStations },
  computed: {
    ...mapState(["qrcode", "zhcode", "cabinet", "browser", "user", "system"]),
    i18n() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    }
  },
  data() {
    return {
      isScan: false,
      userAgreement: false,
      privacyAgreement: false
    }
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    // 頭部主頁圖標按鈕
    goHome() {
      this.$store.commit("qrcode", "")
      // console.log('ditu', gMapContext)
      this.isScan = false
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;

  .borrow {
    &-header {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      padding-top: 80px;
    }
    &_logo {
      width: 160px;
    }
    &_scan_guid {
      width: 42%;
      margin-top: 80px;
    }
    &_desc {
      width: 80%;
      margin-top: 16px;
      font-size: 40px;
      color: #000;
      text-align: center;
    }

    &_btn {
      margin: 64px 40px 0;
      padding: 6px 0;
      border-radius: 2em;
      font-weight: bold;
    }
  }
}
</style>

<template>
  <div class="recommended" v-if="shopList.length">
    <div class="recommended_title">{{ i18n.borrow_recommended }}</div>
    <div class="shop-warp">
      <div
        :key="item.id"
        class="shop-item"
        v-for="item in shopList"
        @click="shopDetails(item)"
      >
        <div class="shop-banner">
          <img
            alt=""
            class="shop-banner-pic"
            :src="item.shopBanner || require('@/assets/imgs/banner-home.jpg')"
          />
        </div>
        <div class="item-gourp">
          <p class="item-gourp-name">{{ item.shopName }}</p>
          <span>{{ item.distance }}</span>
        </div>
        <img
          alt=""
          class="item_navigation"
          :src="require('@/assets/imgs/icon_navigation.png')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SHOP_LIST_NEW } from "../../apis/cabinet"
import userMixin from "../../mixins/user"
export default {
  mixins: [userMixin],
  data() {
    return {
      shopList: []
    }
  },
  computed: {
    i18n() {
      return this.$t("home")
    }
  },
  methods: {
    // 站点详情
    shopDetails(item) {
      this.$store.commit("shopInfo", item)
      this.$router.push(`/shop/details/${item.id}`)
    },
    // 获取状态
    onlineStatus(infoStatus) {
      return {
        text: this.$t(`map.${infoStatus}`),
        class: infoStatus === "在线" ? "online" : ""
      }
    },
    // 获取周边站点
    async getNearbyShop(param = {}) {
      const { lat, lng } = await this.getLocation()
      const queryDate = {
        zoomLevel: 5,
        showPrice: true,
        usePriceUnit: true,
        coordType: "WGS-84",
        lat: lat || 0,
        lng: lng || 0,
        ...param
      }
      let formdata = new FormData()
      Object.keys(queryDate).forEach((key) => {
        formdata.append(key, queryDate[key])
      })
      const _headers = { headers: { "Content-Type": "multipart/form-data" } }
      this.$post(
        SHOP_LIST_NEW,
        formdata,
        (resp) => {
          this.$loading(false)
          let shopList = resp.list
          if (shopList.length > 3) {
            shopList = shopList.slice(0, 3)
          }
          this.shopList = shopList
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        },
        _headers
      )
    }
  },
  created() {
    this.getNearbyShop()
  }
}
</script>

<style lang="less" scoped>
.recommended {
  margin: 60px 30px 0;
  &_title {
    font-size: 28px;
    color: #75bb48;
    margin: 0 0 40px 10px;
  }
}
.shop-warp {
  display: flex;
  .shop-item {
    width: 28%;
    position: relative;
    margin: 0 10px;
    padding: 30px 18px 14px;
    border: 2px solid #d5d5d5;
    border-radius: 16px;
    .shop-banner {
      position: relative;
      width: 100%;
      height: 170px;
      &-pic {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .item-gourp {
      font-size: 18px;
      color: #b5b5b5;
      &-name {
        margin: 6px 0 12px;
        color: #333333;
        font-size: 20px;
      }
    }
    .item_navigation {
      position: absolute;
      right: 28px;
      bottom: 12px;
      width: 40px;
    }
  }
}
</style>
